<template>
    <div class="signin">
        <Header :title="title"></Header>
        <div class="container">
            <div class="mb-2">
                <label for="email">이메일</label>
                <!-- <input type="text" id="email" class="form-control mb-3" ref="email" v-model.trim="$v.email.$model">-->
                <input type="text" id="email" class="form-control" ref="email" v-model.trim="$v.email.$model" required aria-required="true">
                <div v-if="$v.email.$error">
                    <div class="error" v-if="!$v.email.required">이메일을 입력해주세요.</div>
                    <div class="error" v-if="!$v.email.emailChk">이메일 형식으로 입력해주세요.</div>
                </div>
            </div>
        
            <div class="mb-2">
                <label for="password">패스워드</label>
                <input type="password" id="password" class="form-control" ref="password" v-model.trim="$v.password.$model">
                <div v-if="$v.password.$error">
                    <div class="error" v-if="!$v.password.required">패스워드를 입력해주세요.</div>
                    <div class="error" v-if="!$v.password.alphaNumAndDotAndSpecialCharValidator">비밀번호는 영문,숫자,특수문자($@$!%*#?&)를 포함한 8자리이상으로 작성해주세요.</div>
                </div>
            </div>
            <div class="mb-2">
                <label for="password">패스워드 확인</label>
                <input type="password" id="passwordConf" class="form-control" ref="passwordConf" v-model.trim="$v.passwordconfirm.$model">
                <div v-if="$v.passwordconfirm.$error">
                    <div class="error" v-if="!$v.passwordconfirm.required">패스워드 확인을 입력해주세요.</div>
                    <div class="error" v-if="!$v.passwordconfirm.sameAsPassword">패스워드와 동일하게 입력해주세요.</div>
                </div>
            </div>
            <button class="btn btn-primary login_btn w-100" :disabled="email==''||password==''||passwordconfirm==''" @click="ValidatiorChk">회원가입</button>
        </div>
    </div>
</template>

<script>
import UserAgent from 'user-agents';
import Header from '@/components/common/Header.vue'
import { required, email, minLength, sameAs, helpers } from 'vuelidate/lib/validators';
const alphaNumAndDotAndSpecialCharValidator = helpers.regex('alphaNumAndDotAndSpecialChar', /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]*$/i);
export default {
    components:{Header},
    data(){
        return{
            api : this.$store.state.api,
            title:'회원가입',
            email:'',
            password:'',
            passwordconfirm:'',
            signBtnNone: true
        }
    },
    validations: {
        email: {
            required, emailChk: email
        },
        password: {
            required, alphaNumAndDotAndSpecialCharValidator, minEightLength: minLength(8)
        },
        passwordconfirm: {
            required, sameAsPassword: sameAs('password')
        }
    },
    methods: {
        ValidatiorChk(){
            const Email_required_Chk = this.$v.email.required;
            const Email_Chk = this.$v.email.emailChk;

            const Password_required_Chk = this.$v.password.required;
            const Password_Validator_Chk = this.$v.password.alphaNumAndDotAndSpecialCharValidator;

            const PasswordConfirm_required_Chk = this.$v.passwordconfirm.required;
            const PasswordConfirm_SameAs_Chk = this.$v.passwordconfirm.sameAsPassword;

            if(!Email_required_Chk) {
                return this.$refs.email.focus();
            }
            else if(!Password_required_Chk){
                return this.$refs.password.focus();
            }
            else if(!PasswordConfirm_required_Chk){
                return this.$refs.passwordConf.focus();
            }
            else if(!Email_Chk){
                return this.$refs.email.focus();
            }
            else if(!Password_Validator_Chk){
                return this.$refs.password.focus();
            }
            else if(!PasswordConfirm_SameAs_Chk){
                return this.$refs.passwordConf.focus();
            }
            else{
                this.SignClick();
            }

        },
        SignClick(){
            // const email = this.email;
            // const password = this.password;
            // const joinPath = this.$route.query.joinPath;
            // const choiseTerm = this.$route.query.term;

            // const api = this.api;
            // this.$http.post(`${api}/front/sign/member/join`, { email, password, joinPath, choiseTerm }
            // ).then(
            //     res => {
            //         if(res.status == 200){
            //             const code = res.data.code;
            //             if(code == 200) {
            //                 const mem_no = res.data.mem_no;
            //                 this.SaveMemberLog(mem_no, "회원가입");
            //             }
            //             else if(code == 100){
            //                 this.$alert("기존에 가입된 이메일입니다.");
            //             }
            //             else if(code == 300){
            //                 this.$alert("기존에 가입된 휴대폰 번호입니다.");
            //             }
            //             else {
            //                 this.$alert("가입에 실패했습니다. 계속해서 실패 시 1대1문의 바랍니다.");
            //             }
            //         }
            //     }
            // )    
            this.$router.push('/login')        
        },
        SaveMemberLog(mem_no, action){
            // const userAgent = new UserAgent();
            // const agentString = JSON.stringify(userAgent.data, null, 2);

            // const api = this.api;
            // this.$http.post(`${api}/front/main/member/log`, { mem_no, action, userAgent: agentString }
            // ).then(
            //     res => {
            //         if(res.status == 200){
            //             const user = {no: mem_no};
            //             if(res.data.code == 200) this.$store.dispatch('SETLOGIN', user).then(() => { this.$router.push("/main") });
            //         }
            //     }
            // )
        }
    },
}
</script>

<style lang="scss" scoped>
label{
    font-size: 12px;
}
.form-control:focus,.form-select:focus{
    outline: 0;
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.15rem rgba(13, 110, 253,.25);
}
.error{
    width: 100%;
    margin-top: 10px;
    font-size: 11.7px;
    color: #dc3545;
    padding-left: 5px;
}
</style>