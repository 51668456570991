<template>
    <div class="login">
        <Header :title="title"></Header>
        <div class="container">
            <div class="mb-2">
                <label for="email">이메일</label>
                <input type="text" id="email" class="form-control" ref="email" v-model.trim="$v.email.$model">
                <div v-if="$v.email.$error">
                    <div class="error" v-if="!$v.email.required">이메일을 입력해주세요.</div>
                    <div class="error" v-if="!$v.email.emailChk">이메일 형식으로 입력해주세요.</div>
                </div>
            </div>
        
            <div class="mb-2">
                <label for="password">패스워드</label>
                <input type="password" id="password" class="form-control" ref="password" v-model.trim="$v.password.$model">
                <div v-if="$v.password.$error">
                    <div class="error" v-if="!$v.password.required">패스워드를 입력해주세요.</div>
                </div>
            </div>
            <button class="btn btn-primary login_btn w-100" :disabled="email==''||password==''" @click="loginBtn()">로그인</button>
            <router-link to="/" class="d-block text-center fs-sm my-3">이메일/패스워드 찾기</router-link>
            <div class="sns_btn py-3">
                <button class="btn btn-outline-primary w-100" @click="EmailJoinBtn">회원가입</button>
            </div>
        </div>
    </div>
</template>

<script>
import UserAgent from 'user-agents';
import Header from '@/components/common/Header.vue'
import { required, email } from 'vuelidate/lib/validators';
export default {
    components:{Header},
    data(){
        return{
            api : this.$store.state.api,
            title:'로그인',
            email:'',
            password:'',
            mobile: false
        }
    },
    validations: {
        email: {
            required, emailChk: email
        },
        password: {
            required
        },
    },
    created(){
    },
    mounted() {        
    },
    methods:{
        ValidatiorChk(){
            const Email_required_Chk = this.$v.email.required;
            const Email_Chk = this.$v.email.emailChk;

            const Password_required_Chk = this.$v.password.required;

            if(!Email_required_Chk) {
                return this.$refs.email.focus();
            }
            else if(!Email_Chk){
                return this.$refs.email.focus();
            }
            else if(!Password_required_Chk){
                return this.$refs.password.focus();
            }
            else{
                this.loginBtn();
            }
        },
        loginBtn(){
            this.$router.push('/main/misa');
        },
        EmailJoinBtn(){
            this.$router.push({path:'/agreement'})
        },
    }
}
</script>

<style lang="scss" scoped>
label{
    font-size: 12px;
}
.kakaoTalk{
    background-color: #fee404;
    font-weight: bold;
    color: #52423c;
    font-size: 14px;
}

.form-control:focus,.form-select:focus{
    outline: 0;
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.15rem rgba(13, 110, 253,.25);
}
.error{
    width: 100%;
    margin-top: 10px;
    font-size: 11.7px;
    color: #dc3545;
    padding-left: 5px;
}
</style>